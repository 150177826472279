<template>
  <div
      class="container inside-wrapper music-case-study inside-wrapper-margin"
      id="case-study"
      data-aos="fade-up"
      data-aos-duration="1000"
  >
    <div class="row sittinonmusic-background-img back-white" style="">
      <div class="col-md-5 col-heading">
        <h2 class="heading wow fadeInDown text-blue">
          SittinOnMusic<a href="#"><span class="lnr lnr-link"></span></a>
        </h2>
        <br />
        <p
          class="text-dark wow fadeInUp text-dark subheading text-justify"
        >
          The mobile app was built to connect a community of singers, DJs and
          music enthusiasts who enjoy showcasing their creative skills. The
          original app was developed by an offshore company, but the client was
          dissatisfied with issues including the Android version having features
          not yet available in the iOS version and the inconvenient
          communication with the team. <br />
          <br />
          The original API makes use of AWS for storing images, audio and video
          files. Using React Native we are able to develop the app for both
          Android and iOS simultaneously and keep the business logic in the same
          code base. Total price of the rebuild for this app was less than $25k.
        </p>
        <br /><br />
        <a href="#" class="web-blue" target="_blank"
          ><span class="web-icon-blue"><i class="fa fa-mobile"></i> </span>
          <span class="web-text-blue text-bold">View App</span></a
        >
        <br /><br /><br />
      </div>
    </div>
    <section class="case-study">
      <div class="row top-height">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-12">
              <div class="margin-top-section">
                <h2 class="heding-title">
                  1<span class="sub-title-pad sub-title-text"
                    >Re-designed App Layout</span
                  >
                </h2>
              </div>
              <ul type="none" class="whyORT">
                <li>
                  <i class="fa fa-minus text-red">&nbsp;&nbsp;</i
                  ><span
                    > Make better use of screen spacing and use icons for
                    increased functionality</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="app-screens-mobile">
                <img
                  width="100%"
                  class="hidden-xs"
                  src="../../assets/img/casestudy-sittinonmusic/01.jpg"
                />
                <img
                  width="100%"
                  class="visible-xs"
                  src="../../assets/img/casestudy-sittinonmusic/mobile_01.jpg"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="margin-top-section">
                <h2 class="heding-title">
                  2<span class="sub-title-pad sub-title-text"
                    >Better UI/UX</span
                  >
                </h2>
              </div>
              <ul type="none" class="whyORT">
                <li>
                  <i class="fa fa-minus text-red">&nbsp;&nbsp;</i
                  ><span
                    > Updates to the API provides more efficiency and more
                    elements to be viewed on the screen</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="app-screens-mobile">
                <img
                  width="100%"
                  class="hidden-xs"
                  src="../../assets/img/casestudy-sittinonmusic/02.jpg"
                />
                <img
                  width="100%"
                  class="visible-xs"
                  src="../../assets/img/casestudy-sittinonmusic/mobile_02.jpg"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="margin-top">
                <h2 class="heding-title">
                  3<span class="sub-title-pad"
                    >ReEngineered Core Functionality</span
                  >
                </h2>
              </div>
              <ul type="none" class="whyORT">
                <li>
                  <i class="fa fa-minus text-red">&nbsp;&nbsp;</i
                  ><span
                    > The recording of videos is the main feature of the app so
                    it was reengineered to increase performance on both Android
                    and iOS devices</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="app-screens-mobile">
                <img
                  width="100%"
                  class="hidden-xs"
                  src="../../assets/img/casestudy-sittinonmusic/03.jpg"
                />
                <img
                  width="100%"
                  class="visible-xs"
                  src="../../assets/img/casestudy-sittinonmusic/mobile_03.jpg"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="margin-top">
                <h2 class="heding-title">
                  4<span class="sub-title-pad">Additional Screens</span>
                </h2>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3 col-xs-6">
              <div class="app-all-screens">
                <img src="../../assets/img/casestudy-sittinonmusic/07.png" />
              </div>
            </div>
            <div class="col-sm-3 col-xs-6">
              <div class="app-all-screens">
                <img src="../../assets/img/casestudy-sittinonmusic/05.png" />
              </div>
            </div>
            <div class="col-sm-3 col-xs-6">
              <div class="app-all-screens">
                <img src="../../assets/img/casestudy-sittinonmusic/06.png" />
              </div>
            </div>
            <div class="col-sm-3 col-xs-6">
              <div class="app-all-screens">
                <img src="../../assets/img/casestudy-sittinonmusic/08.png" />
              </div>
            </div>
          </div>

          <div class="col-sm-8 col-technologies text">
            <h2 class="text-dark border-dotted-blue">
              Whichever services you require, we have the skills to match.
            </h2>
            <router-link to="/contact" class="btn-red hvr-sweep-to-right hvr-sweep-to-right-red">
                Contact
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Home
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/news">
          <h2 class="heading text-light">
            News <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "work",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
